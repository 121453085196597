import React from 'react'
import {
  Container,
  LinkButton,
  Section,
  Seo,
} from '@components'
import { LandingPageLayout } from '../../../components/site/layout/landing-page-layout'

const videoId = 'Uq1O-NJwF9E'

const MitmWebinarWatchPage = ({ location }) => {
  return (
    <LandingPageLayout pathname={location.pathname}>
      
      <Section className="pt-16 pb-8 bg-white">
        <Container className="container-md">
          <div className="flex flex-col items-center">
            <h1 className="mb-12 text-5xl text-center">
              Making MitM Attacks A Thing Of The Past
            </h1>
            <h2 className="mt-0 mb-12 text-gray-800">7 October 2021</h2>
            <p>
              Certificate pinning is often stated as a requirement for securing
              APIs, but it is a technique which is not well understood. In this
              free webinar replay, Approov experts demystify certificate
              pinning; they explain what it is, why it's important and how to
              implement and deploy it for maximum efficiency and impact.
            </p>

            <div className="flex items-center justify-center w-full">
              <div className="w-full border border-gray-300 aspect-w-16 aspect-h-9">
                <iframe
                  title="Approov Your APIs"
                  src={`https://www.youtube-nocookie.com/embed/${videoId}`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>

            <p>
              Approov can help your business secure its mobile APIs quickly and
              effectively. Want to learn more about how we helped dozens of
              companies ensure that only their mobile apps can use their APIs,
              including dynamic certificate pinning? Talk to us.
            </p>

            <LinkButton
              className="button-secondary button-lg"
              href="/product/consult"
            >
              Talk to an Expert
            </LinkButton>
          </div>
        </Container>
      </Section>
    </LandingPageLayout>
  )
}

export default MitmWebinarWatchPage
